import { styled } from '@mui/system'
import { number } from 'prop-types'
/**
 * --vh is a viewport height unit set by ViewportUnit component
 * @see @bluheadless/ui-logic/src/components/viewport-unit/viewport-unit.js
 */
const Root = styled('div')`
	//overflow: hidden; this line commented for banner with sticky text
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	${({ offset, updateHeightOnInteraction, coveredPercentage }) => {
		const headerHeight = updateHeightOnInteraction ? '--header-sticky-top' : '--header-height-filled'
		const height =
			offset !== undefined
				? `calc(calc(var(--vh, 1vh) * 100) - ${offset}px)`
				: `calc(
					var(--vh, 1vh) * 100 - ${coveredPercentage?.xs ? 100 - coveredPercentage.xs : 0}vh - var(${headerHeight}, 0px))`
		return { height }
	}}
	${({ theme }) => theme.breakpoints.up('md')} {
		${({ offset, updateHeightOnInteraction, coveredPercentage }) => {
			const headerHeight = updateHeightOnInteraction ? '--header-sticky-top' : '--header-height-filled'

			const height =
				offset !== undefined
					? `calc(calc(var(--vh, 1vh) * 100) - ${offset}px)`
					: `calc(
					var(--vh, 1vh) * 100 - ${coveredPercentage?.md ? 100 - coveredPercentage.md : 0}vh - var(${headerHeight}, 0px))`

			return { height }
		}}
	}
	transition: height 0.2s ease-out;
`

const AboveTheFold = ({ offset, children, ...props }) => {
	return (
		<Root offset={offset} {...props}>
			{children}
		</Root>
	)
}

AboveTheFold.propTypes = {
	offset: number,
}

export default AboveTheFold
